
import { defineComponent } from 'vue'

import UserHeader from '@/components/molecules/UserHeader.vue'
import AuthButtonGroup from '@/components/molecules/AuthButtonGroup.vue'
import VideoContainer from '@/components/molecules/VideoContainer.vue'
import FooterUT from '@/components/molecules/FooterUT.vue'
// import StatisticBlock from '@/components/molecules/StatisticBlock.vue'

export default defineComponent({
  name: 'CharitySection',
  data () {
    return {
      translateData: {}
    }
  },
  components: {
    UserHeader,
    AuthButtonGroup,
    VideoContainer,
    FooterUT
    // StatisticBlock
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('charity', 'join_now')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
