
import { defineComponent } from 'vue'
import CharitySection from '@/components/sections/CharitySection.vue'

export default defineComponent({
  name: 'Charity',
  components: {
    CharitySection
  }
})
